<template>
  <div>
    <v-row dense>
      <v-col
        md="12"
        cols="12"
      >
        <v-form ref="form" class="multi-col-validation">
          <v-row dense>
            <v-col
              md="12"
              cols="12"
            >
              <v-card
                flat
                dense
              >
                <v-toolbar dense dark color="primary">
                  <v-toolbar-title><h5 class="font-weight-light">AGENT'S INFORMATION</h5>
                  </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="mt-3">
                  <v-row dense>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="full_name"
                        label="Full Name"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="branch"
                        label="Branch"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      md="4"
                      cols="12"
                    >
                      <v-text-field
                        v-model="position"
                        label="Position"
                        dense
                        outlined
                        readonly
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <v-row>
                <v-col md="5"
                       cols="12">
                  <v-card
                    flat
                    dense
                  >
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">COMPENSATION SECTION</h5>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-row dense>
                        <v-col md="6" cols="12" v-if="type_of_employee==='BM Incentives'">
                          <v-text-field
                            background-color="warning"
                            style="font-size: xx-large"
                            v-model="royalty"
                            label="Royalty"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="6"
                          cols="12"
                        >
                          <v-text-field
                            background-color="info"
                            style="font-size: x-large"
                            v-model="gross_salary_formated"
                            label="Gross Salary"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col md="7"
                       cols="12">
                  <v-card
                    flat
                    dense
                  >
                    <v-toolbar dense dark color="primary">
                      <v-toolbar-title><h5 class="font-weight-light">SALARY DEDUCTION SECTION</h5>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-card-text class="mt-3">
                      <v-row dense>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_sss"
                            label="Unpaid service"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_loan"
                            label="Loans"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_auto_loan"
                            label="Auto Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_philhealth"
                            label="Unremitted Collection"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_ca"
                            label="Cash Advance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_coop_loan"
                            label="COOP Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_pagibig"
                            label="Foundation"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_plan"
                            label="Plan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_tax"
                            label="TAX"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_motor"
                            label="Motor Finance"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_orec"
                            label="Other Receivable"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_sss_loan"
                            label="SSS Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_cash_bond"
                            label="Cash Bond"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_pagibig_loan"
                            label="Pag-Ibig Loan"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-model="deduction_others"
                            label="Others"
                            dense
                            outlined
                            type="number"
                            min="0"
                            @change="computation_of_salary"
                            @keyup="computation_of_salary"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            v-if="deduction_others>0"
                            v-model="other_details"
                            label="Specify Others"
                            dense
                            outlined
                            :rules="rules.default_max_45_character_and_no_empty_rule"
                          ></v-text-field>
                        </v-col>
                        <v-col
                          md="4"
                          cols="12"
                        >
                          <v-text-field
                            background-color="warning"
                            style="font-size: xx-large"
                            v-model="net_salary_formated"
                            label="Net Salary"
                            dense
                            outlined
                            readonly
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <!-- alert -->
                <v-col cols="12" v-show="alert">
                  <v-alert
                    color="warning"
                    text
                    class="mb-0"

                  >
                    <div class="d-flex align-start">
                      <v-icon color="warning">
                        {{ icons.mdiAlertOutline }}
                      </v-icon>

                      <div class="ms-3">
                        <p class="text-base font-weight-medium mb-1">
                          {{ alert_message }}
                        </p>
                      </div>
                    </div>
                  </v-alert>
                </v-col>
                <v-col cols="12" md="12">
                  <v-btn
                    class="w-full"
                    color="primary"
                    @click="save_employee"
                    v-if="!saving"
                  >
                    Save changes
                  </v-btn>
                  <v-progress-circular
                    :size=50
                    :width="5"
                    color="primary"
                    indeterminate
                    v-else
                  ></v-progress-circular>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
import {mdiPencilOutline,} from "@mdi/js";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

const initialState = () => {
  return {
    saving: false,
    alert: false,
    alert_message: '',

    full_name: '',
    branch: '',
    position: '',
    status: '',
    date_of_employment: '',
    employment_duration: '',
    type_of_employee: '',
    compensation_rate: 0,
    no_of_days: 0,
    holiday_pay: 0,
    holiday_pay_details: '',
    bod_allowance: 0,
    rice_allowance: 0,
    birthday_allowances: 0,
    trainee_allowances: 0,
    incentives_allowances: 0,
    gross_salary: 0,
    incentives_chapel: 0,
    gross_salary_formated: '0.00',
    deduction_sss: 0,
    deduction_loan: 0,
    deduction_auto_loan: 0,
    deduction_philhealth: 0,
    deduction_ca: 0,
    deduction_coop_loan: 0,
    deduction_pagibig: 0,
    deduction_plan: 0,
    deduction_coop_save: 0,
    deduction_late: 0,
    deduction_tax: 0,
    deduction_motor: 0,
    deduction_orec: 0,
    deduction_sss_loan: 0,
    deduction_cash_bond: 0,
    royalty: 0,
    other_incentives: 0,
    deduction_pagibig_loan: 0,
    no_of_absent: 0,
    deduction_others: 0,
    other_details: '',
    net_salary: 0,
    net_salary_formated: '0.00',

    particulars: '',
    category_text: '',

    bank_type: '',
    bank_account_name: '',
    bank_account_no: '',
  }
}
export default {
  props: {
    value: Object,
  },
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiPencilOutline,
      },
      is_deleting: false,
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.full_name = this.value.name
    this.branch = this.value.branch
    this.position = this.value.position
    this.status = this.value.status
    this.type_of_employee = this.value.particulars

    this.compensation_rate = this.value.compensation_rate
    this.particulars = this.value.particulars
    this.bank_type = this.value.bank_type
    this.bank_account_name = this.value.bank_account_name
    this.bank_account_no = this.value.bank_account_no

    this.incentives_chapel = this.value.gross_salary
    this.gross_salary = this.value.gross_salary

    this.deduction_sss = this.value.deduction_sss
    this.deduction_sss_loan = this.value.deduction_sss_loan
    this.deduction_cash_bond = this.value.deduction_cash_bond
    this.deduction_loan = this.value.deduction_loan
    this.deduction_auto_loan = this.value.deduction_auto_loan
    this.deduction_philhealth = this.value.deduction_philhealth
    this.deduction_ca = this.value.deduction_ca
    this.deduction_coop_loan = this.value.deduction_coop_loan
    this.deduction_pagibig = this.value.deduction_pagibig
    this.deduction_pagibig_loan = this.value.deduction_pagibig_loan
    this.deduction_plan = this.value.deduction_plan
    this.deduction_coop_save = this.value.deduction_coop_save
    this.deduction_late = this.value.deduction_late
    this.deduction_tax = this.value.deduction_tax
    this.deduction_motor = this.value.deduction_motor
    this.no_of_absent = this.value.no_of_absent
    this.deduction_orec = this.value.deduction_orec
    this.deduction_others = this.value.deduction_others
    this.deduction_cash_bond = this.value.deduction_cash_bond
    this.other_details = this.value.other_details
    this.net_salary = this.value.net_salary


    this.gross_salary_formated = this.formatPrice(this.gross_salary)
    this.net_salary_formated = this.formatPrice(this.net_salary)
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['name']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('salaries_and_wages', ['update_salary_incentives']),
    date_format(value) {
      return moment(value)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    reset() {
      this.saving = false
      this.particulars = ''
      this.full_name = ''
      this.branch = ''
      this.position = ''
      this.status = ''
      this.date_of_employment = ''
      this.employment_duration = ''
      this.bank_type = ''
      this.bank_account_name = ''
      this.bank_account_no = ''
      this.compensation_rate = 0
      this.no_of_days = 0
      this.holiday_pay = 0
      this.royalty = 0
      this.other_incentives = 0
      this.holiday_pay_details = ''
      this.bod_allowance = 0
      this.rice_allowance = 0
      this.birthday_allowances = 0
      this.trainee_allowances = 0
      this.incentives_allowances = 0
      this.gross_salary = 0
      this.gross_salary_formated = '0.00'
      this.deduction_sss = 0
      this.deduction_loan = 0
      this.deduction_auto_loan = 0
      this.deduction_philhealth = 0
      this.deduction_ca = 0
      this.deduction_coop_loan = 0
      this.deduction_pagibig = 0
      this.deduction_plan = 0
      this.deduction_coop_save = 0
      this.deduction_late = 0
      this.deduction_tax = 0
      this.deduction_motor = 0
      this.deduction_orec = 0
      this.deduction_pagibig_loan = 0
      this.deduction_sss_loan = 0
      this.deduction_cash_bond = 0
      this.no_of_absent = 0
      this.deduction_others = 0
      this.other_details = ''
      this.net_salary = 0
      this.net_salary_formated = '0.00'
      this.selectedDepositId = -1
    },
    computation_of_salary() {
      this.check_if_input_number_correct()

      var deductions = parseFloat(this.deduction_sss) + parseFloat(this.deduction_loan) + parseFloat(this.deduction_auto_loan) + parseFloat(this.deduction_philhealth) + parseFloat(this.deduction_ca) + parseFloat(this.deduction_coop_loan) + parseFloat(this.deduction_pagibig) + parseFloat(this.deduction_plan) + parseFloat(this.deduction_coop_save) + parseFloat(this.deduction_late) + parseFloat(this.deduction_tax) + parseFloat(this.deduction_motor) + parseFloat(this.deduction_others) + parseFloat(this.deduction_orec) + parseFloat(this.deduction_sss_loan) + parseFloat(this.deduction_cash_bond) + parseFloat(this.deduction_pagibig_loan)

      this.gross_salary = parseFloat(this.incentives_chapel)
      this.net_salary = parseFloat(this.royalty) > parseFloat(this.gross_salary) ? (parseFloat(this.royalty) - parseFloat(deductions)) : (parseFloat(this.gross_salary) - parseFloat(deductions))

      this.gross_salary_formated = this.formatPrice(this.gross_salary)
      this.net_salary_formated = this.formatPrice(this.net_salary)
    },
    check_if_input_number_correct() {
      if (this.no_of_days > 31 || this.no_of_days < 0 || this.no_of_days === '') {
        this.no_of_days = 0
      }
      if (this.holiday_pay < 0 || this.holiday_pay === '') {
        this.holiday_pay = 0
      }
      if (this.royalty < 0 || this.royalty === '') {
        this.royalty = 0
      }
      if (this.other_incentives < 0 || this.other_incentives === '') {
        this.other_incentives = 0
      }
      if (this.rice_allowance < 0 || this.rice_allowance === '') {
        this.rice_allowance = 0
      }
      if (this.birthday_allowances < 0 || this.birthday_allowances === '') {
        this.birthday_allowances = 0
      }
      if (this.trainee_allowances < 0 || this.trainee_allowances === '') {
        this.trainee_allowances = 0
      }
      if (this.incentives_allowances < 0 || this.incentives_allowances === '') {
        this.incentives_allowances = 0
      }
      if (this.bod_allowance < 0 || this.bod_allowance === '') {
        this.bod_allowance = 0
      }
      if (this.deduction_sss < 0 || this.deduction_sss === '') {
        this.deduction_sss = 0
      }
      if (this.deduction_loan < 0 || this.deduction_loan === '') {
        this.deduction_loan = 0
      }
      if (this.deduction_auto_loan < 0 || this.deduction_auto_loan === '') {
        this.deduction_auto_loan = 0
      }
      if (this.deduction_philhealth < 0 || this.deduction_philhealth === '') {
        this.deduction_philhealth = 0
      }
      if (this.deduction_ca < 0 || this.deduction_ca === '') {
        this.deduction_ca = 0
      }
      if (this.deduction_coop_loan < 0 || this.deduction_coop_loan === '') {
        this.deduction_coop_loan = 0
      }
      if (this.deduction_pagibig < 0 || this.deduction_pagibig === '') {
        this.deduction_pagibig = 0
      }
      if (this.deduction_plan < 0 || this.deduction_plan === '') {
        this.deduction_plan = 0
      }
      if (this.deduction_coop_save < 0 || this.deduction_coop_save === '') {
        this.deduction_coop_save = 0
      }
      if (this.deduction_late < 0 || this.deduction_late === '') {
        this.deduction_late = 0
      }
      if (this.no_of_absent < 0 || this.no_of_absent === '') {
        this.no_of_absent = 0
      }
      if (this.deduction_tax < 0 || this.deduction_tax === '') {
        this.deduction_tax = 0
      }
      if (this.deduction_motor < 0 || this.deduction_motor === '') {
        this.deduction_motor = 0
      }
      if (this.deduction_others < 0 || this.deduction_others === '') {
        this.deduction_others = 0
      }
      if (this.deduction_orec < 0 || this.deduction_orec === '') {
        this.deduction_orec = 0
      }
      if (this.deduction_sss_loan < 0 || this.deduction_sss_loan === '') {
        this.deduction_sss_loan = 0
      }
      if (this.deduction_cash_bond < 0 || this.deduction_cash_bond === '') {
        this.deduction_cash_bond = 0
      }
      if (this.deduction_pagibig_loan < 0 || this.deduction_pagibig_loan === '') {
        this.deduction_pagibig_loan = 0
      }
    },
    save_employee() {
      this.saving = true
      this.alert = false
      if (this.$refs.form.validate()) {
        const data = new FormData()
        data.append('id', this.value.id);

        data.append('duration', this.duration);
        data.append('batch_no', this.batch_no);
        data.append('branch', this.branch);
        data.append('position', this.position);
        data.append('status', this.status);
        data.append('bank_type', this.bank_type);
        data.append('bank_account_name', this.bank_account_name);
        data.append('bank_account_no', this.bank_account_no);

        data.append('royalty', this.royalty)
        data.append('gross_salary', this.gross_salary);

        data.append('bod_allowance', this.bod_allowance);
        data.append('rice_allowance', this.rice_allowance);
        data.append('trainee_allowances', this.trainee_allowances);
        data.append('birthday_allowances', this.birthday_allowances);
        data.append('transpo_allowances', 0);
        data.append('accomodation_allowances', 0);
        data.append('incentives_allowances', this.incentives_allowances);
        data.append('holiday_pay', this.holiday_pay);
        data.append('holiday_pay_details', this.holiday_pay_details);

        data.append('deduction_sss', this.deduction_sss);
        data.append('deduction_philhealth', this.deduction_philhealth);
        data.append('deduction_pagibig', this.deduction_pagibig);
        data.append('deduction_loan', this.deduction_loan);
        data.append('deduction_auto_loan', this.deduction_auto_loan);
        data.append('deduction_coop_loan', this.deduction_coop_loan);
        data.append('deduction_late', this.deduction_late);
        data.append('deduction_coop_save', this.deduction_coop_save);
        data.append('deduction_ca', this.deduction_ca);
        data.append('deduction_plan', this.deduction_plan);
        data.append('deduction_others', this.deduction_others);
        data.append('other_details', this.other_details);
        data.append('deduction_tax', this.deduction_tax);
        data.append('deduction_motor', this.deduction_motor);
        data.append('deduction_pagibig_loan', this.deduction_pagibig_loan)
        data.append('deduction_sss_loan', this.deduction_sss_loan)
        data.append('deduction_orec', this.deduction_orec)
        data.append('deduction_cash_bond', this.deduction_cash_bond)

        data.append('net_salary', this.net_salary);

        data.append('prepared_by', this.name);


        this.update_salary_incentives(data)
          .then(response => {
            this.change_snackbar({
              show: true,
              color: 'success',
              text: response.data,
            })
            this.$emit('data', true)
          })
          .catch(error => {
            this.alert = true
            this.alert_message = error
            this.saving = false
          })
      } else {
        this.alert = true
        this.alert_message = 'Please Fill up the field/s'
        this.saving = false
      }
    }
  },
}
</script>
